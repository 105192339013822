const { GlobalState } = require('reflux')

let isLocalMicOpen = null
let recognition = null

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

document.addEventListener('toggle-audio', ({ detail }) => {
    console.log('toggle-audio',detail.audioTrack.isEnabled) 
    if(detail.audioTrack){
        isLocalMicOpen = !!detail.audioTrack.isEnabled;
        if(!isLocalMicOpen) {
            console.log('stopping recognition')
            try {
                recognition && recognition.close()
            } catch (error) {
                console.log('Recognition already closed.')
            }
        } else {
            console.log('starting recognition')
            try {
                recognition && recognition.start()
            } catch (error) {
                console.log('Recognition already started.')
            }
        }
    } else {
        console.log('Warning: No audio track...')
    }
})


export const closeAwsTranscriber = function () {
  console.log('transcriber trigger to close.')
  try {
    recognition && recognition.close()
    recognition = null;
  } catch (error) {
    console.log(error.message)
  } 
}
       
export const streamAudioToWebSocket = function (userMediaStream, broadcast, generateId, shouldTranscribe, track) {
    recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;
    recognition.continuous = true;
    recognition.onresult = (event) => {
        const result = event.results[event.results.length - 1];
        const transcript = result[0].transcript;
        console.log('transcript', transcript)

        const raw_transcription = {
            text: transcript,
            timestamp: Date.now(),
            t_id: generateId()
        }

        if ((typeof track.isEnabled === 'boolean' && !track.isEnabled) || (typeof track.enabled === 'boolean' && !track.enabled) || track.muted) return
        if(!shouldTranscribe) return
        if(isLocalMicOpen) {
            broadcast(raw_transcription)
    
            const transcribeEvent = new CustomEvent('transcribe', { detail: { transcript: '...', speaker: GlobalState.auth.jwt.data.username }})
            document.dispatchEvent(transcribeEvent)
        }
    }
    recognition.start();
}

export const initializeAwsTranscriber = (broadcast, generateId, shouldTranscribe, track) => {
    // const { transcribe } = GlobalState.main.db_meeting.settings;
    if(shouldTranscribe) {
        console.log('Initializing transcriber.')
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log('getUserMedia supported.')
        navigator.mediaDevices
        .getUserMedia(
            // constraints - only audio needed for this app
            {
            audio: true,
            video: false,
            },
        )
    
        // Success callback
        .then((mediaStream) => streamAudioToWebSocket(mediaStream, broadcast, generateId, shouldTranscribe, track))
    
        // Error callback
        .catch(function (err) {
            console.log('The following getUserMedia error occurred: ' + err)
            // toggle stop
        })
    } else {
        console.log('getUserMedia not supported on your browser!')
    }
    }
}