import { createActions, Store } from 'reflux'
import normalizeUrl from 'normalize-url'
import deepCopy from 'deep-copy'
import Alert from 'react-s-alert'
const jsondiffpatch = require('jsondiffpatch').create({
	cloneDiffValues: true,
	minLength: 1e10,
})
import { toast } from 'react-toastify';
// import knockSound from '../../assets/knocksound.wav'

import { NotificationActions } from '/stores/NotificationStore'
import { AuthActions } from '/stores/AuthStore'
import WidgetPurgers from '/loop-widgets/Purgers'
import Encryption from '/helpers/Encryption'
import LoopApi from '/helpers/LoopApi'
import Sagas from '/helpers/Sagas'
import { VariableCallingActions } from './VariableCallingStore'
import UserKnocking from '../components/Notifications/UserKnocking'
import useToastify from '../helpers/useToastify'
import { Slide } from 'react-toastify'
import Api, { Actions, Endpoints } from '../../src/loop-widgets/_Shared/Api'
import useLocalAudioToggle from '../screens/Meeting2.0/Widgets/KeynoteWidgets/hooks/useLocalAudioToggle'
import jwtDecode from "jwt-decode";

const { GlobalState } = require('reflux')

export var MainActions = createActions([
	'SetBotMode',
	'SetTimeTravelState',
	'WipeMeeting',
	'MeetingConnection',
	'AddTranscription',
	'StaredTranscription',
    'SaveStaredTranscription',
    'DeleteStarTranscription',
	'TranscriptionSaved',
	'RemoveStarTranscription',
	'DbMeeting',
	'UserKnocking',
	'IgnoreKnockingUser',
	'AllowKnockingUser',
	'SetupPhase',
	'AddMeetingFile',
	'Presentation',
	'RemoveMeetingFile',
	'UpdateMeetingFile',
	'WidgetDataCreated',
	'SetUpdateUser',
	'RemoveKnockingToaster',
	'DeniedKnockingUser',
	'SetDevices',
	'SetTheme',
	'SetActiveSinkId',
	'RenameRoom',
	'SetRoomAvatar',
	'SetCalendarOpen',
	'UpdateAlias'
])

// These are actually actions that can be called from the websocket messages
export var WidgetActions = createActions([
	'UpdateWidget',
	'SelectWidget',
	'CreateOrUpdateWidget',
	'TryUpdateWidget',
	'SetMeeting',
	'SetUsers',
	'SetWidgets',
	'DeleteWidget',
	'ClearWidget',
	'BufferedKeyUpdate',
	'UserWidgetScroll',
	'AddLink',
	'ScrollIntoView',
	'RequestPurgeWidget',
	'SendNotification',
	'TryRefreshToken',
	'TranscriptionResult',
	'SetCurrentWidget',
	'SetLocalCurrentWidget',
	'RemoveLink',
	'AddPhotos',
	'HideSync',
	'RemoveLinkSync',
	'ReorderWidgets',
	'NotifyUsersTaskWithEmail',
	'GoogleCheckPresenting',
	'SetPresentation',
	'SetLastView',
	'ExpandFloatingWidgets',
])

const IsJsonString = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

const initialMeetingState = {
	bot_mode: false,
	userScrolls: {},
	isTimeTraveling: false,
	setupPhase: 'loading',
	meetingConnection: 'disconnected',
	meetingName: null,
	slack_team_id: null,
	team_id: null,
	currentWidget: null,
	recordings: [],
    isMicOpen: false,
	files: [],
	transcription: [],
	widgets: {},
	selectedWidget: '',
	users: [],
	presentation: {},
	db_meeting: {
		bot_settings: {},
		settings: {},
	},
	usersKnocking: [],
	locallyHiddenSync: IsJsonString(localStorage.getItem('minimizedWidgets')) ? JSON.parse(localStorage.getItem('minimizedWidgets')) : [],
	locallySelectedSync: IsJsonString(localStorage.getItem('selectedWidgets')) ? JSON.parse(localStorage.getItem('selectedWidgets')) : [],
	localCurrentWidget: IsJsonString(localStorage.getItem('currentWidget')) ? JSON.parse(localStorage.getItem('currentWidget')) : {},
	localWidgetOrder: IsJsonString(localStorage.getItem('widgetOrder')) ? JSON.parse(localStorage.getItem('widgetOrder')) : {},
	devices: {
		videoDevices: [],
		audioDevices: {
			input: [],
			output: [],
		},
	},
	activeSinkId: localStorage.getItem('audio_output_device_id') || null,
	presentation: {
		googledrive: null,
		dropbox: null,
		files: null
	},
	lastView: {
		googledrive: null,
		dropbox: null,
		files: null
	},

	// Calender
	calendarOpen: false
}
export class MainStore extends Store {
	constructor() {
		super()
		this.state = {
			meetingWebsocket: null,
			...initialMeetingState,
		}
		this.nextNotification = 0

		this.listenables = [MainActions, WidgetActions]

        console.log('MainStore mounted')

        document.addEventListener('toggle-audio', ({ detail }) => {
            console.log('toggle-audio',detail.audioTrack.isEnabled) 
            if(detail.audioTrack){
                this.setState({ isMicOpen: !!detail.audioTrack.isEnabled })
            } else {
                console.log('Warning: No audio track...')
            }
        })
	}

	onUpdateAlias(name_alias) {
		console.log({ name_alias})
		this.setState((prevState) => ({
			...prevState,
			db_meeting: {
				...prevState.db_meeting,
				name_alias
			}
		}))
	}

	onSetBotMode(bot_mode) {
		this.setState({ bot_mode })
	}

	onSetupPhase(setupPhase) {
		this.setState({ setupPhase })
	}

	onAddMeetingFile(file) {
		// let targetWidget = file.targetWidget
		// delete file.targetWidget

		// //console.log('ON ADD MEETING FILE', { targetWidget, currentWidget: this.state.currentWidget, file })

		// switch(targetWidget) {
		// 	default:
		// 		this.setState({ files: [...this.state.files, file] })

		// 		if (!this.state.widgets.files) {
		// 			Sagas.addWidget('files')
		// 		}
		// }

		this.setState({ files: [...this.state.files, file] })

		if (!this.state.widgets.files) {
			Sagas.addWidget('files')
		}


	}

	onRemoveMeetingFile(file) {
		this.setState({
			files: this.state.files.filter(f => f._id !== file._id)
		})
	}

	onRenameRoom(name_alias) {
		this.setState({
			db_meeting: {
				...this.state.db_meeting,
				name_alias
			}
		})
	}
	
	onSetRoomAvatar(avatar_url) {
		this.setState({
			db_meeting: {
				...this.state.db_meeting,
				avatar_url
			}
		})
	}

	onUpdateMeetingFile(file) {
		this.setState({
			files: this.state.files.map(f => {
				if (f._id === file._id) {
					return file
				}
				return f
			})
		})
	}

	onPresentation(presentation) {
		this.setState({ presentation })
	}

	onIgnoreKnockingUser(id) {
		const usersKnocking = this.state.usersKnocking.filter(u => u._id !== id)

		Sagas.Clients.Emit('main', 'message', { action: 'DenyJoinRoom', targetUserId: id, meetingName: this.state.meetingName })
		window.socket.emit('userknocking', { meetingName: this.state.meetingName, info: { success: false, id } })
		this.setState({ usersKnocking })
	}

	onAllowKnockingUser(id) {
		const usersKnocking = this.state.usersKnocking.filter(u => u._id !== id)

		Sagas.Clients.Emit('main', 'message', { action: 'DenyJoinRoom', targetUserId: id, meetingName: this.state.meetingName })
		window.socket.emit('userknocking', { meetingName: this.state.meetingName, info: { success: true, id } })
		this.setState({ usersKnocking })
	}


	onDeniedKnockingUser(id) {
		const usersKnocking = this.state.usersKnocking.filter(u => u._id !== id)
		this.setState({ usersKnocking })
	}

	onUserKnocking(user) {
		if (!this.state.usersKnocking.find(u => u._id === user._id)) {
			useToastify({
				message: () => UserKnocking({ user, meetingName: this.state.meetingName }),
				position: "top-center",
				autoClose: false,
				closeButton: false,
				hideProgressBar: true,
				transition: Slide,
				closeOnClick: false,
				draggable: false,
				toastId: `${user._id}knocking`,
				className: GlobalState.theming.color_scheme === 'Light' ? 'toastL' : 'toastD',
				bodyClassName: "grow-font-size",
				progressClassName: GlobalState.theming.color_scheme === 'Light' ? 'toastProgressL' : 'toastProgressD'

			})
			this.setState({
				usersKnocking: [...this.state.usersKnocking, user],
			})
		}
	}

	onRemoveKnockingToaster({ id }) {
		MainActions.DeniedKnockingUser(id)
		//console.log("===>  removing: ", id, "  ", GlobalState.auth.jwt.data._id)
		toast.dismiss(`${id}knocking`)
	}

	onAddTranscription(record) {
        this.setState({
            transcription: [...(this.state.transcription || []), record],
        })
	}

	onStaredTranscription({ record }) {
        // console.log(this.state.transcription)
        console.log('On stared transcription ==>', record)
		const filteredTranscriptions = this.state.transcription.map(o => {
			if (o.t_id === record.t_id) {
				o.stared = true;
				return o
			}
			return o
		})
		this.setState({
			transcription: filteredTranscriptions,
		})
        Sagas.Clients.Emit('main', 'message', { action: 'saveStarTranscription', record: record, meetingName: this.state.meetingName })
	}

    onSaveStaredTranscription({ record }) {
        // console.log(this.state.transcription)
        console.log('On stared transcription ==>', record)
		const filteredTranscriptions = this.state.transcription.map(o => {
			if (o.t_id === record.t_id) {
				o.stared = true;
				return o
			}
			return o
		})
		this.setState({
			transcription: filteredTranscriptions,
		})
	}

	onTranscriptionSaved() {
		this.setState({
			transcription: [],
		})
	}

	onRemoveStarTranscription({ record }) {
        console.log('On remove star transcription ==>', record)
		const filteredTranscriptions = this.state.transcription.map(o => {
			if (o.t_id === record.t_id) {
				o.stared = false;
				return o
			}
			return o
		})
		this.setState({
			transcription: filteredTranscriptions,
		})
        Sagas.Clients.Emit('main', 'message', { action: 'removeStarTranscriptions', record: record, meetingName: this.state.meetingName })
	}

	onDeleteStarTranscription({ record }) {
        console.log('On remove star transcription ==>', record)
		const filteredTranscriptions = this.state.transcription.map(o => {
			if (o.t_id === record.t_id) {
				o.stared = false;
				return o
			}
			return o
		})
		this.setState({
			transcription: filteredTranscriptions,
		})
	}

	onMeetingConnection(meetingConnection) {
		if (
			this.state.meetingConnection === 'Reconnecting' &&
			this.state.meetingName
		) {
			Sagas.initMeeting(this.state.meetingName)
		}

		this.setState({ meetingConnection })
	}

	onDbMeeting(db_meeting, is_update = false) {
		if (
			is_update &&
			this.state.db_meeting &&
			!this.state.db_meeting.ghost_mode &&
			db_meeting.ghost_mode
		) {
			Sagas.meetingLogoutProcess()
			window.location.reload()
		}

		let dbMeeting = db_meeting

		if (dbMeeting && dbMeeting.settings && dbMeeting.settings.color_scheme) {
			dbMeeting = {
				...dbMeeting,
				settings: {
					...dbMeeting.settings,
					color_scheme: localStorage.getItem('color_scheme') || dbMeeting.settings.color_scheme
				}
			}
		}

		this.setState({ db_meeting: dbMeeting })

		if (db_meeting.locked) {
			window.location.reload()
		}
	}

	onWipeMeeting() {
		this.setState({
			...initialMeetingState,
		})
	}

	onSetTimeTravelState(state, isTimeTraveling = true) {
		this.setState({ meeting: state, isTimeTraveling })
	}

	onTryUpdateWidget(raw_data) {
		const data = this.state.db_meeting.ghost_mode
			? { ...raw_data, delta: Encryption.decrypt(raw_data.delta) }
			: raw_data
		let localWidget = deepCopy(this.state.widgets[data.name])

		NotificationActions.WidgetUpdated(data.name, data.updater)

		jsondiffpatch.patch(localWidget, data.delta)
		localWidget.name = data.name
		const widgets = Object.assign({}, this.state.widgets)
		widgets[data.name] = localWidget
		this.setState({ widgets })
	}

	onSetCurrentWidget({ currentWidget, localPush }) {
		this.setState({ currentWidget })
		if (localPush) {
			const toSet = { ...this.state.localCurrentWidget, [this.state.meetingName]: currentWidget }
			this.onSetLocalCurrentWidget({ localCurrentWidget: toSet })
			localStorage.setItem("currentWidget", JSON.stringify(toSet))
		}
	}

	onSetLocalCurrentWidget({ localCurrentWidget }) {
		this.setState({ localCurrentWidget })
	}

	async onUpdateWidget(newData) {
		if (this.state.isTimeTraveling) {
			return
		}
		const widget = { ...(this.state.widgets[newData.name] || newData) }
		const updated_widget = Object.assign({}, widget, newData)
		const widgets = Object.assign({}, this.state.widgets)
		widgets[newData.name] = updated_widget

		this.setState({ widgets })

		const delta = jsondiffpatch.diff(widget, updated_widget)
		if (!delta) {
			return
		}

		const diff_message = {
			name: newData.name,
			timestamp: Date.now(),
			delta,
		}
		Sagas.updateWidget(newData.name, diff_message)
	}

	async onSetMeeting(passedMeeting) {
		const {
			name,
			slack_team_id,
			team_id,
			recordings,
			files,
			presentation,
			currentWidget,
		} = passedMeeting
		const users = Object.entries(passedMeeting.users).map(([id, user]) => ({
			id,
			...user,
			avatar_url:
				user.avatar_url ||
				`https://ui-avatars.com/api/?name=${user.name}&format=svg&background=${user.color || 'random'}&color=fefefe`,
		}))

		this.setState({
			meetingName: name,
			slack_team_id,
			team_id,
			recordings,
			files,
			users,
			presentation,
			currentWidget,
			setupPhase: 'completed',
		})
		this.onSetWidgets(passedMeeting.widgets)
		VariableCallingActions.SetConnectedUserIds(passedMeeting.webRTCConnectedParticipants || [])
		// CallingActions.SetSFUToken(passedMeeting.sfu_token)
	}

	async onSetUsers(passedUsers) {
		const users = Object.entries(passedUsers).map(([id, user]) => ({
			id,
			...user,
			avatar_url:
				user.avatar_url ||
				`https://ui-avatars.com/api/?name=${user.username || user.name || ''}&format=svg&background=${user.color || 'random'}&color=fefefe`,
			name: user.username || user.name || '',
			username: user.username || user.name || '',
		}))
		this.setState({ users })
	}

	async onSetUpdateUser({id, data, token}) {
		const users = this.state.users.map((user) => user.id === id ? { ...user, ...data, name: data.name || data.username || user.name || data.username || '', username: data.username || data.name || data.username || data.name || '' } : user)
		this.setState({ users })
		if(token && GlobalState.auth.jwt.data._id === id){
			localStorage.token = token
			AuthActions.SetJwt(token)
		}
	}

	async onSetWidgets(passedWidgets) {
		if (this.state.isTimeTraveling) {
			return
		}

		if (Object.keys(passedWidgets).length === 0 && this.state.bot_mode) {
			Sagas.addWidgets([
				'asana',
				'notes',
				'googledrive',
				'images',
				'standup',
				'sketchpad',
			])
		}

		let widgets = { ...this.state.widgets }
		Object.entries(passedWidgets).forEach(([name, w]) => {
			widgets[name] = this.state.db_meeting.ghost_mode
				? Encryption.decrypt(w)
				: w
		})
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	async onDeleteWidget(name) {
		if (this.state.isTimeTraveling) {
			return
		}

		let widgets = { ...this.state.widgets }
		delete widgets[name]
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	async onClearWidget(name) {
		if (this.state.isTimeTraveling) {
			return
		}

		let widgets = { ...this.state.widgets }
		Object.keys(widgets).forEach(function (key) { delete widgets[key]; })
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	async onBufferedKeyUpdate({ key, name, data = [] }) {
		NotificationActions.WidgetUpdated(name)
		let widget = { ...this.state.widgets[name] }
		widget[key] = [...widget[key], ...data]
		let widgets = { ...this.state.widgets }
		widgets[name] = widget
		return new Promise((res, rej) => this.setState({ widgets }, () => res()))
	}

	onUserWidgetScroll({ id, percent }) {
		const userScrolls = { ...this.state.userScrolls }
		if (percent !== null) {
			userScrolls[id] = percent
		} else {
			delete userScrolls[id]
		}

		this.setState({ userScrolls })
	}

	onSelectWidget(widgetName) {
		this.setState({ selectedWidget: widgetName })
	}

	onRequestPurgeWidget(widget_name) {
		let delta = undefined
		if (WidgetPurgers[widget_name]) {
			const newData = WidgetPurgers[widget_name](
				deepCopy(this.state.widgets[widget_name])
			)
			delta = jsondiffpatch.diff(this.state.widgets[widget_name], newData)
		}

		Sagas.purgeWidget(widget_name, delta)
	}

	onSendNotification({ target_user_id, body, widget, target_user_ids, endpoint}) {
		let payload

        if (endpoint === 'NotifyUsers') {
			payload = {
				target_user_ids,
				body,
				widget
			}
		} else {
			if (Date.now() < this.nextNotification) {
				return
			}

			this.nextNotification = Date.now() + 10e3
			endpoint = target_user_id ? 'NotifyUser' : 'NotifyAll';
			payload = {
				widget,
				body,
				target_user_id,
			}
		}

		LoopApi(null, endpoint, payload).catch(e => console.error(e))
	}

	onNotifyUsersTaskWithEmail({ target_user_ids, task }) {
		LoopApi(null, 'NotifyUsersTaskWithEmail', { target_user_ids, task }).catch(e => console.error(e))
	}

	async onCreateOrUpdateWidget(config, makeMain = false) {
		if (!this.state.widgets[config.name]) {
			const extraData = { ...config }
			delete extraData.name
			Sagas.addWidget(config.name, extraData)
		} else {
			this.onUpdateWidget(config)
		}

		if (makeMain) {
			WidgetActions.SetCurrentWidget({ currentWidget: config.name })
			WidgetActions.SetLocalCurrentWidget({ localCurrentWidget: { ...this.state.localCurrentWidget, [this.state.meetingName]: config.name } })
		}
	}

	onTryRefreshToken(strategy) {
		AuthActions.TryRefreshToken(strategy)
	}

	onTranscriptionResult(raw_transcription) {
		const transcription = this.state.db_meeting.ghost_mode
			? Encryption.encrypt(raw_transcription)
			: raw_transcription

		LoopApi('main', 'AudioTranscription', transcription)
	}

	onHideSync(widget_name, synced) {

		let minWidgets = this.state.locallyHiddenSync
		let locallyHiddenSync = {}

		const IsJsonString = (str) => {
			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}
			return true;
		}

		if (IsJsonString(minWidgets)) {
			locallyHiddenSync = JSON.parse(minWidgets) || {}
		} else {
			locallyHiddenSync = minWidgets || {}
		}

		let index = locallyHiddenSync && locallyHiddenSync[this.state.meetingName] ? locallyHiddenSync[this.state.meetingName].indexOf(widget_name) : -1

		if (synced) {
			minWidgets = this.state.db_meeting && this.state.db_meeting.settings && this.state.db_meeting.settings.minimizedWidgets ? this.state.db_meeting.settings.minimizedWidgets : []
			index = minWidgets.indexOf(widget_name) !== -1 ? -1 : minWidgets.indexOf(widget_name)
		}

		if (index === -1) {
			// if(widget_name === this.state.currentWidget) {
			const widgets = Object.keys(this.state.widgets)
			let widgetWithoutHidden = widgets.filter((w) => locallyHiddenSync && locallyHiddenSync[this.state.meetingName] && !locallyHiddenSync[this.state.meetingName].includes(w))

			if (synced) {
				widgetWithoutHidden = widgets.filter((w) => !minWidgets.includes(w))
			}

			const curIndex = widgetWithoutHidden.indexOf(widget_name)
			const curWidget =
				// if only one sync is on the sidebar
				curIndex === 0 && widgetWithoutHidden.length === 1 ? null
					// if the active sync is the first item on the list with multiple syncs added
					: curIndex === 0 ? widgetWithoutHidden[1]
						// if the active sync is the last item on the list with multiple syncs added
						: curIndex === widgetWithoutHidden.length - 1 && widgetWithoutHidden.length > 2 ? widgetWithoutHidden[widgetWithoutHidden.length - 2]
							// else
							: widgetWithoutHidden[curIndex + 1]

			// LOCAL STATE CURRENT WIDGET
			if (!synced) {
				const localCurrentWidget = {
					...this.state.localCurrentWidget,
					[this.state.meetingName]: curWidget
				}
				localStorage.setItem("currentWidget", JSON.stringify(localCurrentWidget))
				WidgetActions.SetLocalCurrentWidget({ localCurrentWidget })

				const cur = locallyHiddenSync[this.state.meetingName] || []
				cur.push(widget_name)
				locallyHiddenSync = {
					...locallyHiddenSync,
					[this.state.meetingName]: cur
				}
			} else {
				// SYNCED BEHAVIOR CURRENT WIDGET
				WidgetActions.SetCurrentWidget({ currentWidget: curWidget })
			}

			// }

		} else {
			// LOCAL STATE CURRENT WIDGET
			if (!synced) {
				const localCurrentWidget = {
					...this.state.localCurrentWidget,
					[this.state.meetingName]: widget_name || ''
				}
				localStorage.setItem("currentWidget", JSON.stringify(localCurrentWidget))
				WidgetActions.SetLocalCurrentWidget({ localCurrentWidget })

				const cur = locallyHiddenSync[this.state.meetingName] || []
				cur.splice(index, 1)
				locallyHiddenSync = {
					...locallyHiddenSync,
					[this.state.meetingName]: cur
				}
			} else {
				// SYNCED BEHAVIOR CURRENT WIDGET
				WidgetActions.SetCurrentWidget({ currentWidget: widget_name })
			}
		}
		if (!synced) {
			// LOCAL STATE HIDE WIDGET
			localStorage.setItem('minimizedWidgets', JSON.stringify(locallyHiddenSync))
			this.setState({ locallyHiddenSync })
		}
	}

	onExpandFloatingWidgets({ currentWidget, localPush }) {
		WidgetActions.SetCurrentWidget({ currentWidget, localPush })
		let locallyHiddenSync = this.state.locallyHiddenSync
		let index = locallyHiddenSync && locallyHiddenSync[this.state.meetingName] ? locallyHiddenSync[this.state.meetingName].indexOf(currentWidget) : -1
		if(index !== -1) {
			const cur = Array.from(locallyHiddenSync[this.state.meetingName])
			cur.splice(index, 1)
			locallyHiddenSync = {
				...locallyHiddenSync,
				[this.state.meetingName]: cur
			}
			this.setState({ locallyHiddenSync })
			localStorage.setItem('minimizedWidgets', JSON.stringify(locallyHiddenSync))
		}
	}

	async onAddLink(urls, callback, widget = null) {
		let normalizedUrls = []
		//console.log({ urls })
		//normalize urls
		if(widget === 'links') {
			normalizedUrls = [{
				url: normalizeUrl(urls.url, { stripWWW: false }),
				name: urls.name,
				description: urls.description,
				creator: urls.creator
			}]
		} else {
			if (Array.isArray(urls))
				urls.map(url => normalizedUrls.push(normalizeUrl(url, { stripWWW: false })))
			else normalizedUrls = [normalizeUrl(urls, { stripWWW: false })]
		}

		const urlmetadatas = await LoopApi(null, 'GetUrlMetadata', {
			urls: normalizedUrls,
		})

		
		if (urlmetadatas.error && widget === 'links') return callback({action: 'invalid'})
		
		const foundLinks = urlmetadatas.filter(u => u.type === 'link')
		const foundImages = urlmetadatas.filter(u => u.type === 'image')
		
		if (foundImages.length > 0) {
			NotificationActions.WidgetUpdated('images')
			if (!this.state.widgets.images) {
				return Sagas.addWidget('images', {
					images: foundImages.reduce((acc, curr, index) => {
						const item = {
							...curr,
							indx: index
						}
						return {
							...acc,
							[index]: item
						}
					}, {}),
					current_image: foundImages[0],
				})
			}

			let filterImgs = this.state.widgets.images.images
			//filter duplicates
			const imagesArr = Array.isArray(this.state.widgets.images.images) ?
			this.state.widgets.images.images : (Object.values(this.state.widgets.images.images) || []).sort((a, b) => a.indx - b.indx)
			const storedImages = imagesArr.map(img => img.url)
			filterImgs = foundImages.filter(
				img => !storedImages.includes(img.url)
			)

			//update the images
			if (filterImgs.length > 0) {
				let images = [...imagesArr, ...filterImgs]
							.reduce((acc, curr, index) => {
								const item = {
									...curr,
									indx: index
								}
								return {
									...acc,
									[index]: item
								}
							}, {})
				WidgetActions.UpdateWidget({
					name: 'images',
					images,
					current_image: filterImgs[0],
				})
			}
		}
		//console.log({ state: this.state })

		if (foundLinks.length > 0) {
			const linksArrayToObj = (arr) => {
				return (arr || []).reduce((acc, curr) => {
					const indx = curr.timestamp
					return {
						...acc,
						[indx]: curr
					}
				}, {})
			}

			NotificationActions.WidgetUpdated('links')
			if (!this.state.widgets.links) {
				return Sagas.addWidget('links', { links: linksArrayToObj(foundLinks)})
			}

			const stateLinks = this.state.widgets.links.links
			const links = !stateLinks ? [] : Array.isArray(stateLinks) ?
			stateLinks : Object.values(stateLinks)

			const storedUrls = links.map(meta => meta.url)
			const urls = foundLinks.reduce((acc, curr) => {
				if (storedUrls.includes(curr.url)) {
					acc.duplicates.push(links.find(s => s.url === curr.url))
					return { ...acc, duplicates: acc.duplicates }
				} else {
					acc.filteredUrlMetas.push(curr)
					return { ...acc, filteredUrlMetas: acc.filteredUrlMetas }
				}
			}, { duplicates: [], filteredUrlMetas: [] })

			if (urls.duplicates.length > 0) return callback ? callback({
				action: 'duplicate',
				data: {
					val: true,
					link: urls.duplicates[0]
				}
			}) : null

			//update the links
			if (urls.filteredUrlMetas.length > 0) {
				const alllinks = linksArrayToObj([...links, ...urls.filteredUrlMetas])
				WidgetActions.UpdateWidget({ name: 'links', links: alllinks })
				callback({action: 'success'})
			}
		}
	}

	async onRemoveLink(url) {
		if (!this.state.widgets.images || !this.state.widgets.images.images) {
			return
		}
		//console.log(this.state.widgets.images)

		const imagesArr = Array.isArray(this.state.widgets.images.images) ?
		this.state.widgets.images.images : (Object.values(this.state.widgets.images.images) || []).sort((a, b) => a.indx - b.indx)

		if (this.state.widgets.images.current_image && this.state.widgets.images.current_image.url === url) {
			// move current image
			const filtered = imagesArr.filter(image => image.url !== url)
			let reassignedCurrent = {};
			if (filtered && filtered.length > 0) {
				reassignedCurrent = filtered[filtered.length - 1];
			}
			WidgetActions.UpdateWidget({
				name: 'images',
				images: filtered.reduce((acc, curr, index) => {
					const item = {
						...curr,
						indx: index
					}
					return {
						...acc,
						[index]: item
					}
				}, {}),
				current_image: reassignedCurrent
			})
		} else {
			WidgetActions.UpdateWidget({
				name: 'images',
				images: imagesArr.filter(image => image.url !== url).reduce((acc, curr, index) => {
					const item = {
						...curr,
						indx: index
					}
					return {
						...acc,
						[index]: item
					}
				}, {}),
			})
		}

		// if (this.state.widgets.images.images.length === 1) {
		// 	WidgetActions.UpdateWidget({
		// 		name: 'images',
		// 		images: [],
		// 		current_image: {}
		// 	})
		// } else {
		// 	WidgetActions.UpdateWidget({
		// 		name: 'images',
		// 		images: this.state.widgets.images.images.filter(image => image.url !== url),
		// 	})
		// }
	}

	async onAddPhotos(files) {

		// const files = files.map(file => {
		// 	return {
		// 		type: 'image',
		// 		url: file.url,
		// 		image_hash: null
		// 	}
		// })

		// NotificationActions.WidgetUpdated('images')
		// if (!this.state.widgets.images) {
		// 	return Sagas.addWidget('images', {
		// 		images: foundImages,
		// 		current_image: foundImages[0],
		// 	})
		// }

		// const storedImages = this.state.widgets.images.images.map(img => img.url)
		// //filter duplicates
		// const filterImgs = foundImages.filter(
		// 	img => !storedImages.includes(img.url)
		// )

		// //update the images
		// if (filterImgs.length > 0) {
		// 	const images = [...this.state.widgets.images.images, ...filterImgs]
		// 	WidgetActions.UpdateWidget({
		// 		name: 'images',
		// 		images,
		// 		current_image: filterImgs[0],
		// 	})
		// }
	}

	async onWidgetDataCreated({ type, data }) {
		const event = new CustomEvent(`WIDGET-${data.widgetName}`, { detail: { type, data } })
		window.dispatchEvent(event)
	}

	async onRemoveLinkSync(url) {
		const stateLinks = this.state.widgets.links.links
		if (stateLinks) {
			const links = !stateLinks ? [] : Array.isArray(stateLinks) ?
			stateLinks : Object.values(stateLinks)

			if(links.findIndex(l => l.url === url) !== -1) {
				const filteredUrls = links.filter(links => links.url !== url)
				filteredUrls && WidgetActions.UpdateWidget({
					name: 'links',
					links: filteredUrls.reduce((acc, curr) => {
						const indx = curr.timestamp
						return {
							...acc,
							[indx]: curr
						}
					}, {})
				})
			}

		}
	}

	onSetDevices(devices) {
		this.setState({ devices })
	}

	onSetTheme(color_scheme) {
		localStorage.setItem('color_scheme', color_scheme)
		this.setState((prevState) => ({
			...prevState,
			db_meeting: {
				...prevState.db_meeting,
				settings: {
					...prevState.db_meeting.settings,
					color_scheme
				}
			}
		}))
	}

	onSetActiveSinkId(activeSinkId) {

		// //console.log('onSetActiveSinkId', activeSinkId)

		if (activeSinkId) {
			localStorage.setItem('audio_output_device_id', activeSinkId)
		} else {
			localStorage.removeItem('audio_output_device_id')
		}

		this.setState({ activeSinkId })
	}

	onReorderWidgets(widgets) {
		this.setState({
			localWidgetOrder: {
				...this.state.localWidgetOrder,
				[this.state.db_meeting._id]: widgets
			}
		})

		const lwo = localStorage.getItem('widgetOrder')
		let toSave = {}

		if(lwo) {
			const items = JSON.parse(lwo)
			toSave = {
				...items,
				[this.state.db_meeting._id]: widgets
			}
		} else {
			toSave = {
				[this.state.db_meeting._id]: widgets
			}
		}

		LoopApi(null, 'UpdateWidgetOrder', { widgets })
          .then((res) => {
			//   localStorage.token = res.token
			//   AuthActions.SetJwt(res.token)
          })
          .catch((error) => {
            console.log({ error })
          });

		localStorage.setItem('widgetOrder', JSON.stringify(toSave))
	}

	async onGoogleCheckPresenting(token, cb) {
		await new Promise(async(done) => {
			await Api(Endpoints['GoogleDrive'], Actions[`DELETEFiles`], token, {
				urlAppend: `/${this.state.widgets.googledrive.file.id}/permissions/anyoneWithLink`,
				body: undefined,
			})
			WidgetActions.UpdateWidget({
				file: null,
				public_permission: '',
				userId: null,
				name: 'googledrive'
			})
			return setTimeout(done, 500)
		})

		WidgetActions.SetPresentation({
			sync: 'googledrive',
			val: null
		})
		await cb()
	}

	onSetPresentation({sync, val}) {
		this.setState({
			presentation: {
				...this.state.presentation,
				[sync]: val
			}
		})
	}

	onSetLastView({sync, data}) {
		this.setState({
			lastView: {
				...this.state.lastView,
				[sync]: data
			}
		})
	}

	onSetCalendarOpen(open) {
		this.setState({
			calendarOpen: open
		})
	}
}

MainStore.id = 'main'

/*
widgets:
images:
current_image: {type: "image", url: "https://dev-test-s3-bucket-2021.s3-ap-southeast-1.…naws.com/d1c7eca0-bf63-11eb-b712-13907cccdb55.jpg", image_hash: "%242a%2405%246QveoSNAczgfhlbJVmckf.ckeSjlalHxCOv4vAdSpjfsydASSIVgS"}
images: Array(5)
0:
image_hash: "%242a%2405%24Fd1fF4uzHDC%2FB%2F.H5a16DOxE1p1adcXaTgooyJImcHYht.0q5QGPe"
type: "image"
url: "https://dev-test-s3-bucket-2021.s3-ap-southeast-1.amazonaws.com/0d311d20-bf55-11eb-b712-13907cccdb55.jpg"
__proto__: Object
*/

/*
signed urls
imgUrl: "https://dev-test-s3-bucket-2021.s3-ap-southeast-1.amazonaws.com/b179e4b0-bfba-11eb-b712-13907cccdb55.jpg"
key: "b179e4b0-bfba-11eb-b712-13907cccdb55.jpg"
url: "https://dev-test-s3-bucket-2021.s3.ap-southeast-1.amazonaws.com/b179e4b0-bfba-11eb-b712-13907cccdb55.jpg?Content-Type=image%2Fjpg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIATOHPKMACYDBOWF2W%2F20210528%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20210528T134332Z&X-Amz-Expires=900&X-Amz-Signature=e1c13ad1857e85e73a0fdf47b3d12d9f4fcef4776821f2c8f16dd06ae8dc4c45&X-Amz-SignedHeaders=host"

*/
