"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
exports.theme = {
    monitorWidth: 500,
    textColor: '#ddd',
    borderColor: '#ddd',
    fontSizes: {
        small: '0.8rem',
    },
};
