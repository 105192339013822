{
  "name": "jsondiffpatch",
  "version": "0.2.5",
  "author": "Benjamin Eidelman <beneidel@gmail.com>",
  "description": "Diff & Patch for Javascript objects",
  "contributors": [
    "Benjamin Eidelman <beneidel@gmail.com>"
  ],
  "bin": {
    "jsondiffpatch": "./bin/jsondiffpatch"
  },
  "scripts": {
    "test": "gulp test && gulp test-browser",
    "bump": "gulp bump",
    "cover": "istanbul cover --root src gulp test",
    "cover-report": "open coverage/lcov-report/index.html",
    "cover-publish": "istanbul cover _mocha --report lcovonly && codeclimate < coverage/lcov.info"
  },
  "main": "./src/main",
  "types": "./src/index",
  "repository": {
    "type": "git",
    "url": "https://github.com/benjamine/jsondiffpatch.git"
  },
  "keywords": [
    "json",
    "diff",
    "patch"
  ],
  "dependencies": {
    "chalk": "^0.5.1"
  },
  "devDependencies": {
    "bulk-require": "^0.2.1",
    "codeclimate-test-reporter": "0.0.3",
    "expect.js": "~0.3.1",
    "fiberglass": "0.0.22",
    "gulp": "^3.8.8",
    "istanbul": "^0.3.2",
    "mocha": "^1.21.4"
  },
  "bundleDependencies": [],
  "license": "MIT",
  "engine": {
    "node": ">=0.10"
  },
  "testling": {
    "harness": "mocha",
    "files": "test/index.js",
    "scripts": [
      "build/jsondiffpatch.js",
      "external/diff_match_patch_uncompressed.js"
    ],
    "browsers": [
      "ie/8..latest",
      "chrome/27..latest",
      "firefox/22..latest",
      "safari/5.1..latest",
      "opera/12..latest",
      "iphone/6..latest",
      "ipad/6..latest",
      "android-browser/4.2..latest"
    ]
  },
  "engines": {
    "node": "*"
  },
  "homepage": "https://github.com/benjamine/jsondiffpatch"
}
