// Let this get called by the other helpers.
// I would NOT recommend calling this function directly
export default function _buildOauthRequest(
	baseUrl,
	url = '',
	method = 'GET',
	token = null,
	data = {},
	parameters = []
) {
	let fullUrl = `${baseUrl}${data.urlPrepend || ''}${url}${data.urlAppend || ''}`

	// Parameters should be passed as an
	// array of key-value pairs. Example:
	// [['key', 'value'], ['some_param', 'itsvalue']]
	if (parameters && parameters.length > 0) {
		parameters.forEach(p => {
			fullUrl = fullUrl.replace(`:${p[0]}`, encodeURIComponent(p[1]))
		})
	}

	return new Promise((resolve, reject) => {
		let req = new XMLHttpRequest()
		req.onreadystatechange = (e, d) => {
			if (req.readyState !== 4) {
				return
			}
			let resp
			try {
				resp = data.isBlob ? resp = req.response :
				method === 'DELETE' ? req.responseText : JSON.parse(req.responseText)
			} catch (e) {
				return reject(e)
			}
			if (!resp) {
				return resolve(resp)
			}
			resp.errors && console.log(resp.errors)
			req.status % 200 < 100 ? resolve(resp) : reject(resp)
		}
		req.open(method, fullUrl, true)
		if ((!data.headerSettings || !data.headerSettings.noHeader)) {
            // if(!data.headerSettings.noMultiContentType) {
                req.setRequestHeader('Accept', 'application/json')
            // }
		}
		if (
			!data.headerSettings ||
			(data.headerSettings.contentType !== false &&
				!data.headerSettings.noHeader)
		) {
            // if(!data.headerSettings.noMultiContentType) {
                req.setRequestHeader('Content-Type', 'application/json')
            // }
		}

		if(data.headerSettings && data.headerSettings.headerConfig) {
			Object.keys(data.headerSettings.headerConfig).map(header => {
				return req.setRequestHeader(header, data.headerSettings.headerConfig[header])
			})
		}
		if (token) {
			req.setRequestHeader('Authorization', `Bearer ${token}`)
		}

		req.send(
			(
				data.body instanceof FormData ||
				data.body instanceof File ||
				data.body instanceof Blob
			) ? data.body : JSON.stringify(data.body)
		)
	})
}
